import React, { createContext, useContext, useReducer } from "react";
import {
    PersonnelInfoLayoutRows,
    PersonnelInfoLayoutRowType,
    PERSONNEL_INFO_LAYOUT_ROWS_AMT,
} from "../modules/elements/modal/uploadconsentform/personnelinfo/PersonnelInfoLayoutRows";
import { DateUtil } from "../util/DateUtil";
import { DisclosureQuestions } from "../modules/elements/modal/uploadconsentform/disclosures/DisclosureQuestions";

type BgcConsentFormType = {
    firstName: string;
    middleName?: string;
    lastName: string;
    birthDate: string;
    ssn: { partOne: string; partTwo: string; partThree: string };
    position: string;
    homeAddress: string;
    city: string;
    state: { label: string; value: string };
    textInputState: string;
    zipCode: string;
    previousAddress: string;
    previousCity: string;
    previousState: { label: string; value: string };
    textInputPreviousState: string;
    previousZipCode: string;
    phoneNumber: string;
    driversLicenseState: string;
    driversLicenseNumber: string;
    additionalSearchNames: Array<any>;
    livedInOtherCountry: boolean;
    otherCountryLivedIn: string;
    otherCountryLivedInFrom: string;
    otherCountryLivedInTo: string;
    otherCountryLivedInWhere: string;
    isDrVenCol: boolean;
    isJapan: boolean;
    isUnitedStates: boolean;
    nationalID?: string;
    japanID?: string;
    submittable: boolean;
    postalCode?: string;
    previousPostalCode?: string;
    maidenName?: string;
    arrestHistory?: boolean;
    natureOfOffenseArrest?: string;
    convictionHistory?: boolean;
    natureOfOffenseConviction?: string;
    pendingCharge?: boolean;
    natureOfOffensePendingCharge?: string;
    japanAward?: boolean;
    natureOfJapanAward?: string;
    japanCriminal?: boolean;
    natureOfJapanCriminal?: string;
    language: string;
    consentFormFile: any;
    affiliateId: string;
    title: string;
    [x: string]: any;
};

type UploadBgcFormContext = {
    bgcFormState: BgcConsentFormType;
    dispatchBgcForm: React.Dispatch<any>;
    getFormResultsForUpload: Function;
};

const UploadBgcFormContext = createContext<UploadBgcFormContext>({
    bgcFormState: null,
    dispatchBgcForm: null,
    getFormResultsForUpload: null,
});

const BgcFormActionTypes = {
    INPUT: "INPUT",
    DROPDOWN: "DROPDOWN",
    UMPIRE: "UMPIRE",
    DATE: "DATE",
    SSN_PART_ONE: "SSN_PART_ONE",
    SSN_PART_TWO: "SSN_PART_TWO",
    SSN_PART_THREE: "SSN_PART_THREE",
    RADIO: "RADIO",
    ADDITIONAL_SEARCH_NAMES: "ADDITIONAL_NAMES",
    SET_US_VALUE: "SET_US_VALUE",
    SET_DR_VEN_COL: "SET_DR_VEN_COL",
    SET_JAPAN_VALUE: "SET_JAPAN_VALUE",
    RESET: "RESET",
    SET_ARREST: "SET_ARREST",
    SET_CONVICTION: "SET_CONVICTION",
    SET_PENDING_CHARGE: "SET_PENDING_CHARGE",
    SET_LANGUAGE: "SET_LANGUAGE",
    SET_CONSENT_FORM_FILE: "SET_CONSENT_FORM_FILE",
    SET_JAPAN_AWARD: "SET_JAPAN_AWARD",
    SET_JAPAN_CRIMINAL: "SET_JAPAN_CRIMINAL",
};

const initialFormState = {
    firstName: "",
    middleName: "",
    lastName: "",
    birthDate: "",
    ssn: { partOne: "", partTwo: "", partThree: "" },
    position: "",
    homeAddress: "",
    city: "",
    state: { label: "", value: "" },
    textInputState: "",
    textInputPreviousState: "",
    zipCode: "",
    postalCode: "",
    previousAddress: "",
    previousCity: "",
    previousState: { label: "", value: "" },
    previousZipCode: "",
    phoneNumber: "",
    driversLicenseState: "",
    driversLicenseNumber: "",
    additionalSearchNames: [] as [],
    livedInOtherCountry: false,
    otherCountryLivedIn: "",
    otherCountryLivedInFrom: "",
    otherCountryLivedInTo: "",
    otherCountryLivedInWhere: "",
    nationalID: "",
    japanID: "",
    isDrVenCol: false,
    isJapan: false,
    isUnitedStates: false,
    submittable: false,
    previousPostalCode: "",
    maidenName: "",
    arrestHistory: false,
    natureOfOffenseArrest: "",
    convictionHistory: false,
    natureOfOffenseConviction: "",
    pendingCharge: false,
    natureOfOffensePendingCharge: "",
    japanAward: false,
    natureOfJapanAward: "",
    japanCriminal: false,
    natureOfJapanCriminal: "",
    language: "",
    consentFormFile: { name: "", fileName: "", fileBase64String: "", fileBytes: [] as [], id: "" },
    affiliateId: "",
    title: "",
};

const questionAnswered = (value: any, type: string) => {
    if (type === "dropdown") {
        return !!value.value;
    } else if (type === "radio") {
        return true;
    } else {
        return !!value;
    }
};

const isSubmittable = (state: BgcConsentFormType) => {
    const rowNums = Array.from(new Array(PERSONNEL_INFO_LAYOUT_ROWS_AMT), (x, i) => i + 1);
    const requiredAnswers: any[] = [];
    let result = true;
    rowNums.forEach(rowNum => {
        const requiredQuestions = PersonnelInfoLayoutRows[
            rowNum
        ].filter((question: PersonnelInfoLayoutRowType) => question.required(state));
        requiredAnswers.push(...requiredQuestions);
    });
    if (requiredAnswers.length > 0) {
        requiredAnswers.forEach((answer: PersonnelInfoLayoutRowType) => {
            if (!questionAnswered(state[answer.name], answer.type)) {
                result = false;
            }
        });
    }

    DisclosureQuestions.forEach(question => {
        if (!question.submittable(state)) {
            result = false;
        }
    });

    if (state.language == "") {
        result = false;
    }

    if (!state.consentFormFile || state.consentFormFile?.fileBase64String === "") {
        result = false;
    }
    return result;
};

const resetDependentQuestionState = (
    currentQuestion: PersonnelInfoLayoutRowType,
    state: BgcConsentFormType,
) => {
    if (currentQuestion.dependencies) {
        const rowNums = Array.from(new Array(PERSONNEL_INFO_LAYOUT_ROWS_AMT), (x, i) => i + 1);
        const dependentQuestions = new Array<PersonnelInfoLayoutRowType>();
        currentQuestion.dependencies.forEach((dependency: any) => {
            rowNums.forEach(rowNum => {
                const dependencyQ = PersonnelInfoLayoutRows[rowNum].find(
                    (question: PersonnelInfoLayoutRowType) => question.name === dependency,
                );
                if (dependencyQ) {
                    dependentQuestions.push(dependencyQ);
                }
            });
        });
        dependentQuestions.forEach((q: PersonnelInfoLayoutRowType) => {
            if (q.type === "dropdown") {
                state = { ...state, [q.name]: { value: "", label: "" } };
            } else {
                state = { ...state, [q.name]: "" };
            }
        });
    }

    return state;
};

const reducer = (state: BgcConsentFormType, action: any) => {
    if (action.question) {
        state = resetDependentQuestionState(action.question, state);
    }

    switch (action.type) {
        case BgcFormActionTypes.INPUT:
        case BgcFormActionTypes.DROPDOWN:
        case BgcFormActionTypes.UMPIRE:
        case BgcFormActionTypes.DATE:
        case BgcFormActionTypes.RADIO:
            state[action.name] = action.value;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SSN_PART_ONE:
            state[action.name].partOne = action.value;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SSN_PART_TWO:
            state[action.name].partTwo = action.value;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SSN_PART_THREE:
            state[action.name].partThree = action.value;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.ADDITIONAL_SEARCH_NAMES:
            return {
                ...state,
                additionalSearchNames: [...action.additionalSearchNames],
            };
        case BgcFormActionTypes.SET_DR_VEN_COL:
            return {
                ...state,
                isDrVenCol: action.value,
            };
        case BgcFormActionTypes.SET_JAPAN_VALUE:
            return {
                ...state,
                isJapan: action.value,
            };
        case BgcFormActionTypes.SET_US_VALUE:
            return {
                ...state,
                isUnitedStates: action.value,
            };
        case BgcFormActionTypes.RESET:
            return {
                ...initialFormState,
            };
        case BgcFormActionTypes.SET_ARREST:
            state.arrestHistory = action.arrestHistory;
            state.natureOfOffenseArrest = action.natureOfOffenseArrest;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SET_CONVICTION:
            state.convictionHistory = action.convictionHistory;
            state.natureOfOffenseConviction = action.natureOfOffenseConviction;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SET_PENDING_CHARGE:
            state.pendingCharge = action.pendingCharge;
            state.natureOfOffensePendingCharge = action.natureOfOffensePendingCharge;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SET_JAPAN_AWARD:
            state.japanAward = action.japanAward;
            state.natureOfJapanAward = action.natureOfJapanAward;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SET_JAPAN_CRIMINAL:
            state.japanCriminal = action.japanCriminal;
            state.natureOfJapanCriminal = action.natureOfJapanCriminal;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SET_LANGUAGE:
            state.language = action.language;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        case BgcFormActionTypes.SET_CONSENT_FORM_FILE:
            state.consentFormFile = action.consentFormFile;
            state.submittable = isSubmittable(state);
            return {
                ...state,
            };
        default:
            return state;
    }
};

const UploadBgcFormContextProvider: React.FC = ({ children }) => {
    const [bgcFormState, dispatchBgcForm] = useReducer(reducer, initialFormState);

    const getFormResultsForUpload = () => {
        const consentFormUploadDTO = {
            anotherCountryFrom: bgcFormState.livedInOtherCountry
                ? bgcFormState.otherCountryLivedInFrom
                : "",
            city: bgcFormState.city,
            phoneNumber: bgcFormState.phoneNumber,
            preZip: bgcFormState.isUnitedStates
                ? bgcFormState.previousZipCode
                : bgcFormState.previousPostalCode,
            dlState: bgcFormState.driversLicenseState,
            preCity: bgcFormState.previousCity,
            ssn: bgcFormState.isUnitedStates
                ? bgcFormState.ssn.partOne + bgcFormState.ssn.partTwo + bgcFormState.ssn.partThree
                : bgcFormState.isDrVenCol
                ? bgcFormState.nationalID
                : bgcFormState.japanID,
            fullName: bgcFormState.lastName + ", " + bgcFormState.firstName,
            otherNames: JSON.stringify(bgcFormState.additionalSearchNames),
            state: bgcFormState.isUnitedStates
                ? bgcFormState.state.value
                : bgcFormState.textInputState,
            previousAddress: bgcFormState.previousAddress,
            livedAnotherCountry: bgcFormState.livedInOtherCountry,
            anotherCountryState: bgcFormState.otherCountryLivedInWhere,
            preState: bgcFormState.isUnitedStates
                ? bgcFormState.previousState.value
                : bgcFormState.textInputPreviousState,
            zip: bgcFormState.isUnitedStates ? bgcFormState.zipCode : bgcFormState.postalCode,
            anotherCountryName: bgcFormState.otherCountryLivedIn,
            homeAddress: bgcFormState.homeAddress,
            middleName: bgcFormState.middleName,
            dob: DateUtil.formatDate(bgcFormState.birthDate, "MM/DD/YYYY"),
            anotherCountryTo: bgcFormState.livedInOtherCountry
                ? bgcFormState.otherCountryLivedInTo
                : "",
            dlNumber: bgcFormState.driversLicenseNumber,
            maidenName: bgcFormState.maidenName,
            consentFormUploadFileDTO: {
                fileBytes: bgcFormState.consentFormFile.fileBytes,
                fileName: bgcFormState.consentFormFile.fileName,
                fileBase64String: bgcFormState.consentFormFile.fileBase64String,
            },
            arrestHistory: bgcFormState.arrestHistory ? "yes" : null,
            convictionHistory: bgcFormState.convictionHistory ? "yes" : null,
            pendingCharge: bgcFormState.pendingCharge ? "yes" : null,
            natureOfOffenseArrest: bgcFormState.natureOfOffenseArrest,
            natureOfOffenseConvictionHistory: bgcFormState.natureOfOffenseConviction,
            natureOfOffensePendingCharge: bgcFormState.natureOfOffensePendingCharge,
            japanAward: bgcFormState.japanAward,
            natureOfJapanAward: bgcFormState.natureOfJapanAward,
            japanCriminal: bgcFormState.japanCriminal,
            natureOfJapanCriminal: bgcFormState.natureOfJapanCriminal,
            language: bgcFormState.language,
            position: bgcFormState.position,
            affiliateId: bgcFormState.affiliateId,
            title: bgcFormState.title,
        };
        return consentFormUploadDTO;
    };

    return (
        <UploadBgcFormContext.Provider
            value={{ bgcFormState, dispatchBgcForm, getFormResultsForUpload }}
        >
            {children}
        </UploadBgcFormContext.Provider>
    );
};

const useUploadBgcForm = (): UploadBgcFormContext => {
    const context = useContext<UploadBgcFormContext>(UploadBgcFormContext);
    if (context === undefined) {
        throw new Error(`useUploadBgcForm must be used within a UploadBgcFormContextProvider`);
    }
    return context;
};

export { UploadBgcFormContextProvider, useUploadBgcForm, BgcFormActionTypes, BgcConsentFormType };
