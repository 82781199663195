import React, { SetStateAction, Dispatch, useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../../../contexts/AuthContext";
import { PrimaryButtonStyled } from "../../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../../OutlinedButtonStyled";
import QuestionnaireSection from "./QuestionnaireSection";
import { useQuestionnaire } from "../../../../contexts/QuestionnaireContext";
import { QuestionDefinitions } from "./QuestionDefinitions";
import {
    submitShortQuestionnaire,
    getCompletedQuestionnaireResult,
} from "../../../../api/QuestionnaireApi";
import UploadBgcSections from "./UploadBgcSections";
import FormUtil from "../../../../util/FormUtil";
import { useGlobalModal } from "../../../../contexts/GlobalModalContext";
import { useUploadBgcForm, BgcFormActionTypes } from "../../../../contexts/UploadBgcFormContext";
import { uploadConsentFormContent } from "../../../../api/ConsentFormApi";
import { useAlert } from "../../../../contexts/AlertsContext";
import { getAllActiveUmpires, getAllOrgs } from "../../../../api/AffiliateApi";
import { compare } from "../../../../util/SortUtil";

const UploadSectionBodyContent = styled.div.attrs(() => ({ className: "mt-2 ml-2 mr-2" }))`
    &&& {
        height: 441px;
        overflow-y: auto;
    }
`;

const QuestionnaireSectionBodyContent = styled.div.attrs(() => ({ className: "mt-2 ml-2 mr-2" }))`
    &&& {
        height: 350px;
    }
`;

const OverrideTextStyle = styled.div.attrs(() => ({}))`
    width: 100%;
    font-size: 16px;
    color: var(--slate);
`;

type UploadConsentFormBodyProps = {
    personnel: any;
    questionnaireCompletedStatus: { completed: boolean; questionnaireAnswers: {} };
    drugTestingState: boolean;
    setDrugTestingState: Dispatch<SetStateAction<boolean>>;
    jurisdictionForUpload: { label: string; value: string; isCompleted: boolean };
    setJurisdictionForUpload: Dispatch<SetStateAction<{ label: string; value: string }>>;
    jurisdictionOptions: { label: string; value: string }[];
    setJurisdictionOptions: Dispatch<SetStateAction<{ label: string; value: string }[]>>;
    continueClicked: boolean;
    setContinueClicked: Dispatch<SetStateAction<boolean>>;
};

const UploadConsentFormBody: React.FC<UploadConsentFormBodyProps> = ({
    personnel,
    questionnaireCompletedStatus,
    drugTestingState,
    setDrugTestingState,
    jurisdictionForUpload,
    setJurisdictionForUpload,
    jurisdictionOptions,
    setJurisdictionOptions,
    continueClicked,
    setContinueClicked,
}) => {
    const { dispatch } = useGlobalModal();
    const { dispatchQuestionnaire } = useQuestionnaire();
    const { dispatchBgcForm } = useUploadBgcForm();
    const { isAdmin } = useAuth();
    const [umpireNameOptions, setUmpireNameOptions] = useState([]);

    useEffect(() => {
        return () => {
            dispatchQuestionnaire({
                type: "resetState",
            });
            dispatchBgcForm({
                type: BgcFormActionTypes.RESET,
            });
            setJurisdictionOptions([]);
        };
    }, []);

    useEffect(() => {
        getAllActiveUmpires()
            .then(response => {
                setUmpireNameOptions(
                    response
                        .map((org: { orgId: number; name: string; id: number }) => {
                            return {
                                value: org.orgId,
                                label: org.name,
                                id: org.id,
                            };
                        })
                        .sort(
                            (
                                org1: { value: number; label: string },
                                org2: { value: number; label: string },
                            ) => {
                                return compare(org1.label, org2.label, 1, () => {});
                            },
                        ),
                );
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (personnel?.questionnaireResultOnFile) {
            getCompletedQuestionnaireResult(personnel.id).then(response => {
                dispatchQuestionnaire({
                    type: "setShortQuestionnaireResult",
                    questionnaireResult: response,
                });
            });
        }
    }, [personnel]);

    useEffect(() => {
        if (
            questionnaireCompletedStatus?.completed &&
            jurisdictionForUpload?.isCompleted &&
            !continueClicked
        ) {
            dispatch({
                type: "updateModalProperties",
                headerText: "Are You Sure You Want to Continue?",
            });
        } else if (
            questionnaireCompletedStatus?.completed &&
            jurisdictionForUpload?.isCompleted &&
            continueClicked
        ) {
            dispatch({
                type: "updateModalProperties",
                headerText:
                    FormUtil.formatJurisdiction(jurisdictionForUpload.value) + " Consent Form",
            });
        } else if (!questionnaireCompletedStatus?.completed) {
            dispatch({
                type: "updateModalProperties",
                headerText: "Upload Consent Form",
            });
        }
    }, [questionnaireCompletedStatus, jurisdictionForUpload, continueClicked]);

    return (
        <>
            {questionnaireCompletedStatus?.completed ||
            (isAdmin() && personnel?.personnelType?.type == "UMPIRE") ? (
                <>
                    {jurisdictionForUpload?.isCompleted && !continueClicked ? (
                        <div style={{ height: "260px" }} className="mt-2 ml-2 mr-2">
                            <OverrideTextStyle>
                                Uploading a new consent form will replace the existing form that
                                already cleared a background check.
                            </OverrideTextStyle>
                        </div>
                    ) : (
                        <UploadSectionBodyContent>
                            <UploadBgcSections
                                jurisdictionForUpload={jurisdictionForUpload}
                                personnelId={personnel?.id}
                                personnelType={personnel?.personnelType?.type}
                                umpireNameOptions={umpireNameOptions}
                            />
                        </UploadSectionBodyContent>
                    )}
                </>
            ) : (
                <QuestionnaireSectionBodyContent>
                    <QuestionnaireSection
                        personnel={personnel}
                        drugTestingState={drugTestingState}
                        setDrugTestingState={setDrugTestingState}
                        jurisdictionForUpload={jurisdictionForUpload}
                        setJurisdictionForUpload={setJurisdictionForUpload}
                        questionnaireCompletedStatus={questionnaireCompletedStatus}
                        jurisdictionOptions={jurisdictionOptions}
                        setJurisdictionOptions={setJurisdictionOptions}
                    />
                </QuestionnaireSectionBodyContent>
            )}
        </>
    );
};

type UploadConsentFormFooterProps = {
    closeModal: Function;
    personnel: any;
    questionnaireCompletedStatus: { completed: boolean; questionnaireAnswers: {} };
    setQuestionnaireCompletedStatus: Dispatch<
        SetStateAction<{ completed: boolean; questionnaireAnswers: {} }>
    >;
    drugTestingState: boolean;
    jurisdictionForUpload: { label: string; value: string; isCompleted: boolean };
    setJurisdictionForUpload: Dispatch<
        SetStateAction<{ label: string; value: string; isCompleted: boolean }>
    >;
    jurisdictionOptions: { label: string; value: string }[];
    setJurisdictionOptions: Dispatch<SetStateAction<{ label: string; value: string }[]>>;
    continueClicked: boolean;
    setContinueClicked: Dispatch<SetStateAction<boolean>>;
};

const UploadConsentFormFooter: React.FC<UploadConsentFormFooterProps> = ({
    closeModal,
    personnel,
    questionnaireCompletedStatus,
    setQuestionnaireCompletedStatus,
    drugTestingState,
    jurisdictionForUpload,
    setJurisdictionForUpload,
    jurisdictionOptions,
    setJurisdictionOptions,
    continueClicked,
    setContinueClicked,
}) => {
    const { questionnaire, dispatchQuestionnaire } = useQuestionnaire();
    const { bgcFormState, getFormResultsForUpload, dispatchBgcForm } = useUploadBgcForm();
    const showAlert = useAlert();
    const { isAdmin } = useAuth();

    const prepareAnswersPayload = () => {
        const answersPayload = {};

        for (const prop in questionnaire) {
            const question = QuestionDefinitions.find(q => q.id === prop);
            if (!question) {
                continue;
            }

            switch (question.type) {
                case "DropDown":
                    answersPayload[prop] = questionnaire[prop] ? questionnaire[prop].value : null;
                    break;
                case "Text":
                    answersPayload[prop] = questionnaire[prop] ? questionnaire[prop] : null;
                    break;
            }
        }
        return answersPayload;
    };

    const submitQuestionnaire = () => {
        const questionnaireAnswers = prepareAnswersPayload();
        const shortQuestionnaireDTO = {
            questionnaireResult: questionnaireAnswers,
            drugTesting: drugTestingState,
            skipDrugTesting: personnel.dtConsentFormSent || personnel.dtConsentFormOnFile,
        };
        if (questionnaireAnswers) {
            submitShortQuestionnaire(personnel.id, shortQuestionnaireDTO).then(() => {
                setQuestionnaireCompletedStatus({ completed: true, questionnaireAnswers: {} });
                dispatchQuestionnaire({
                    type: "setSubmittedDrugTestState",
                    submittedDrugTestState: drugTestingState === null ? true : drugTestingState,
                });
            });
        }
    };

    const uploadClicked = () => {
        const consentFormUploadDTO = getFormResultsForUpload();
        consentFormUploadDTO.overrideConsentForm = jurisdictionForUpload?.isCompleted || isAdmin();
        let jur = "other";
        if (jurisdictionForUpload != null) {
            jur = jurisdictionForUpload.value;
        }
        uploadConsentFormContent(consentFormUploadDTO, personnel.id, jur)
            .then(response => {
                showAlert("Uploaded", "success");
                setQuestionnaireCompletedStatus({ completed: false, questionnaireAnswers: {} });
                dispatchBgcForm({
                    type: BgcFormActionTypes.RESET,
                });
                setJurisdictionForUpload({ label: "", value: "", isCompleted: false });
                dispatchQuestionnaire({
                    type: "setQuestionnairePreExisting",
                });

                if (response === false) {
                    showAlert(
                        "Error uploading BGC consent form, Please contact BIGS Systems",
                        "danger",
                    );
                }
            })
            .catch(() => {
                showAlert(
                    "Error uploading BGC consent form, Please contact BIGS Systems",
                    "danger",
                );
            });
    };

    return (
        <div className="d-flex">
            <div className="mr-2">
                {questionnaireCompletedStatus?.completed ||
                (isAdmin() && personnel?.personnelType?.type == "UMPIRE") ? (
                    <>
                        <div className="d-flex">
                            <div className="mr-2">
                                {jurisdictionForUpload?.isCompleted && !continueClicked ? (
                                    <PrimaryButtonStyled
                                        width={82}
                                        height={26}
                                        disabled={false}
                                        onClick={event => {
                                            event.stopPropagation();
                                            setContinueClicked(true);
                                        }}
                                    >
                                        Continue
                                    </PrimaryButtonStyled>
                                ) : (
                                    <PrimaryButtonStyled
                                        width={82}
                                        height={26}
                                        disabled={!bgcFormState.submittable}
                                        onClick={event => {
                                            event.stopPropagation();
                                            uploadClicked();
                                        }}
                                    >
                                        Upload
                                    </PrimaryButtonStyled>
                                )}
                            </div>
                            {isAdmin() ? (
                                <OutlinedButtonStyled
                                    width={80}
                                    height={26}
                                    onClick={event => {
                                        event.stopPropagation();
                                        closeModal();
                                    }}
                                >
                                    Close
                                </OutlinedButtonStyled>
                            ) : (
                                <OutlinedButtonStyled
                                    width={185}
                                    height={26}
                                    disabled={false}
                                    onClick={event => {
                                        event.stopPropagation();
                                        setQuestionnaireCompletedStatus({
                                            ...questionnaireCompletedStatus,
                                            completed: false,
                                        });
                                        dispatchQuestionnaire({
                                            type: "setQuestionnairePreExisting",
                                        });
                                    }}
                                >
                                    Back to Questionnaire
                                </OutlinedButtonStyled>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="d-flex">
                        {!questionnaire.isPreExisting ? (
                            <div className="mr-2">
                                <PrimaryButtonStyled
                                    width={165}
                                    height={26}
                                    disabled={
                                        !questionnaire.shortQuestionnaireSubmittable ||
                                        !jurisdictionForUpload
                                    }
                                    onClick={event => {
                                        event.stopPropagation();
                                        submitQuestionnaire();
                                    }}
                                >
                                    Submit Questionnaire
                                </PrimaryButtonStyled>
                            </div>
                        ) : (
                            <div className="mr-2">
                                <PrimaryButtonStyled
                                    width={65}
                                    height={26}
                                    disabled={
                                        !questionnaire.shortQuestionnaireSubmittable ||
                                        jurisdictionForUpload?.value === undefined ||
                                        jurisdictionForUpload?.value === ""
                                    }
                                    onClick={event => {
                                        event.stopPropagation();
                                        submitQuestionnaire();
                                    }}
                                >
                                    Next
                                </PrimaryButtonStyled>
                            </div>
                        )}
                        <OutlinedButtonStyled
                            width={80}
                            height={26}
                            onClick={event => {
                                event.stopPropagation();
                                closeModal();
                            }}
                        >
                            Close
                        </OutlinedButtonStyled>
                    </div>
                )}
            </div>
        </div>
    );
};

UploadConsentFormBody.displayName = "BodyContent";
UploadConsentFormFooter.displayName = "FooterContent";

export { UploadConsentFormBody, UploadConsentFormFooter };
